form, ng-form, *[ng-form] {
  .form-control.ng-touched.ng-valid {
    .has-success .form-control();
  }
  .form-control.ng-touched.ng-invalid {
    .has-error .form-control();
    color: @state-danger-text;
  }
}

.form-block > *{
  display: block;
}
